import React from 'react';
import { Link } from "gatsby"

import Slider from "react-slick";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "../../styles/carousel.css"

import briefcase from "../../images/Home/carousel/briefcase.svg"
import documentImg from "../../images/Home/carousel/document.svg"
import libreta from "../../images/Home/carousel/libreta.svg"
import libro from "../../images/Home/carousel/libro.svg"
import basedatos from "../../images/Home/carousel/basedatos.svg"
import casa from "../../images/Home/carousel/casa.svg"
import libro_cerrado from "../../images/Home/carousel/libro_cerrado.svg"
import senal from "../../images/Home/carousel/senal.svg"
import users from "../../images/Home/carousel/users.svg"
import documento from "../../images/Home/carousel/documento.svg"


import plus_circle from "../../images/Home/carousel/plus-circle.svg"

export default function SeccionTestimonios() {

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        arrows: true,
        responsive: [
            {
                breakpoint: 1125,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 950,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }
        ]

    };

    return (
        <div className="carousel">
            <Slider {...settings} >
                <div className="tarjeta__container">

                    <div className="tarjeta">
                        <img className="tarjeta_img" src={briefcase} alt="maletín" />
                        <p>
                            Proyectos <br />M&A
                    </p>
                        <Link className="contact-btn" to="/servicios">
                            Ver Más
                    <img src={plus_circle} alt="circulo más" />
                        </Link>
                    </div>
                </div>
                <div className="tarjeta__container">

                    <div className="tarjeta">
                        <img className="tarjeta_img" src={documentImg} alt="documento" />
                        <p>
                            Revisión de<br />Documentación Legal
                    </p>
                        <Link className="contact-btn" to="/servicios">
                            Ver Más
                    <img src={plus_circle} alt="circulo más" />
                        </Link>
                    </div>
                </div>
                <div className="tarjeta__container">

                    <div className="tarjeta">
                    <img className="tarjeta_img" src={libro} alt="libro" />
                        <p>
                            Due<br />Dilligence
                    </p>
                        <Link className="contact-btn" to="/servicios" >
                            Ver Más
                    <img src={plus_circle} alt="circulo más" />
                        </Link>
                    </div>
                </div>
                <div className="tarjeta__container">

                    <div className="tarjeta">
                    <img className="tarjeta_img" src={libreta} alt="libreta" />
                        
                        <p>
                            Memorandums
                    </p>
                        <Link className="contact-btn" to="/servicios">
                            Ver Más
                    <img src={plus_circle} alt="circulo más" />
                        </Link>
                    </div>
                </div>
                <div className="tarjeta__container">

                    <div className="tarjeta">
                        <img className="tarjeta_img" src={basedatos} alt="base de datos" />
                        <p>
                        Estructuras<br/>Financiamiento
                    </p>
                        <Link className="contact-btn" to="/servicios">
                            Ver Más
                    <img src={plus_circle} alt="circulo más" />
                        </Link>
                    </div>
                </div>
                <div className="tarjeta__container">

                    <div className="tarjeta">
                        <img className="tarjeta_img" src={casa} alt="casa" />
                        <p>
                        Contratos de <br/>Compraventa
                    </p>
                        <Link className="contact-btn" to="/servicios">
                            Ver Más
                    <img src={plus_circle} alt="circulo más" />
                        </Link>
                    </div>
                </div>
                <div className="tarjeta__container">

                    <div className="tarjeta">
                        <img className="tarjeta_img" src={libro_cerrado} alt="libro cerrado" />
                        <p>
                        Modelos Fiscales
                    </p>
                        <Link className="contact-btn" to="/servicios">
                            Ver Más
                    <img src={plus_circle} alt="circulo más" />
                        </Link>
                    </div>
                </div>
                <div className="tarjeta__container">

                    <div className="tarjeta">
                        <img className="tarjeta_img" src={senal} alt="señal" />
                        <p>
                            Reporte Inversiones<br/>Patrimoniales
                    </p>
                        <Link className="contact-btn" to="/servicios">
                            Ver Más
                    <img src={plus_circle} alt="circulo más" />
                        </Link>
                    </div>
                </div>
                <div className="tarjeta__container">

                    <div className="tarjeta">
                        <img className="tarjeta_img" src={documentImg} alt="documento" />
                        <p>
                        Declaraciones Informativas<br/> REFIPREs
                    </p>
                        <Link className="contact-btn" to="/servicios">
                            Ver Más
                    <img src={plus_circle} alt="circulo más" />
                        </Link>
                    </div>
                </div>
                <div className="tarjeta__container">

                    <div className="tarjeta">
                        <img className="tarjeta_img" src={users} alt="usuarios" />
                        <p>
                        Revisión Nóminas
                    </p>
                        <Link className="contact-btn" to="/servicios">
                            Ver Más
                    <img src={plus_circle} alt="circulo más" />
                        </Link>
                    </div>
                </div>
            </Slider>
        </div>
    )
}