import React from "react"
import { Link } from "gatsby"

import flecha_blanca from "../../images/arrow-down-white.svg"
import bgForm from "../../images/Formulario/Imagen.jpg"

import "../../styles/formulario.css"

export default function Formulario() {
  const handleSubmit = e => {
    e.preventDefault()
    const data = {
      name: e.target.elements[0].value,
      email: e.target.elements[1].value,
      message: e.target.elements[2].value,
    }
    fetch("/api/sendMail", {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })

    document.getElementById("submit_button").style.display = "none"
    document.getElementById("submit_message").style.display = "block"
  }

  return (
    <section>
      <div className="formulario">
        <div className="section_title">
          <div className="preTitle">
            <div className="vector" />
            <span>Contacto</span>
          </div>
          <h2>¿Tienes dudas?</h2>
        </div>
        <form onSubmit={handleSubmit} id="contactForm">
          <div className="input_group">
            <input
              id="name"
              name="name"
              placeholder="Ingresa tu nombre"
              required
              type="text"
            />
            <label htmlFor="name">Nombre</label>
          </div>
          <div className="input_group">
            <input
              id="email"
              name="email"
              placeholder="ejemplo@ejemplo.com"
              required
              type="email"
            />
            <label htmlFor="email">Correo</label>
          </div>
          <div className="input_group">
            <textarea
              id="mensaje"
              name="mensaje"
              placeholder="Ingresa tu mensaje"
              required
              type="text"
            />
            <label htmlFor="mensaje">En que podemos ayudarte</label>
          </div>
          <button type="submit" className="contact-btn" id="submit_button">
            Enviar
            <img src={flecha_blanca} alt="fecha blanca" />
          </button>
          <div id="submit_message" className="submit-message">
            <p>
              Gracias por comunicarte con nosotros, en breve te responderemos.
            </p>
          </div>
        </form>
      </div>
      <div
        className="formulario__picture"
        style={{ backgroundImage: "url(" + bgForm + ")" }}
      />
    </section>
  )
}
