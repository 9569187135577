import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Carousel from "../components/widgets/carousel.js"

import Formulario from "../components/widgets/formulario.js"

import "../styles/banner.css"
import "../styles/contact-btn.css"
import "../styles/section.css"

import flecha from "../images/arrow-down.svg"
import flecha_blanca from "../images/arrow-down-white.svg"
import bgBanner from "../images/Home/header-img.jpg"
import nosotrosImg from "../images/Home/nosotros-img.jpg"

const IndexPage = () => (
  <Layout currentPage={0}>
    <SEO title="Firma de asesoría fiscal" />
    <section className="banner">
      <h1>Nuestra firma asesora, analiza e implementa soluciones fiscales</h1>
      <p>
        Especialistas en asesoría, análisis e implementación de soluciones
        fiscales para personas físicas y morales.
      </p>
      <Link className="contact-btn" to="/contacto">
        Contáctanos
        <img src={flecha_blanca} alt="flecha blanca" />
      </Link>
      <div
        className="banner__picture"
        style={{ backgroundImage: "url(" + bgBanner + ")" }}
      />
    </section>
    <section className="section ">
      <div className="col col-desktop small-col">
        <div
          className="imagen-float"
          style={{
            backgroundImage: "url(" + nosotrosImg + ")",
            position: "absolute",
            zIndex: "1",
          }}
        />
      </div>
      <div className="col big-col topMargin">
        <div className="section_title">
          <div className="preTitle">
            <div className="vector" />
            <span>Nosotros</span>
          </div>
          <h2>¿Quiénes Somos?</h2>
        </div>
        <p>
          Brindamos asesoría fiscal y legal a compañías nacionales y
          multinacionales que buscan proyectos de fusión y adquisiciones (M&A),
          reestructuras corporativas y estructuras de financiamiento. Somos
          expertos en fondos de capital privado con inversiones en México sobre
          todo en los sectores de construcción, salud, infraestructura,
          educación, transporte y logística.
        </p>
        <Link
          className="contact-btn"
          to="/contacto"
          style={{ marginTop: "20px" }}
        >
          Contáctanos
          <img src={flecha} alt="flecha" />
        </Link>
        <div className="col col-responsive">
          <div
            className="imagen-float"
            style={{ backgroundImage: "url(" + nosotrosImg + ")" }}
          />
        </div>
      </div>
    </section>
    <section
      className="section topSpace"
      style={{ backgroundColor: "#f8f8f9" }}
    >
      <div className="section_title">
        <div className="preTitle">
          <div className="vector" />
          <span>Servicios</span>
        </div>
        <h2>¿Cómo podemos ayudarte?</h2>
      </div>
      <Carousel />
      <div className="white_space" />
    </section>
    <section>
      <div className="blue_square">
        <p>
          Vélez Tax es una Firma dedicada a brindar asesoría fiscal a personas
          físicas y morales en México.
        </p>
      </div>
    </section>
    <Formulario />
  </Layout>
)

export default IndexPage
